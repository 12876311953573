import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_192927";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text2_795462";
    item['fr'] = "Authentification en cours…";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text3_288251";
    item['fr'] = "username";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy_663045";
    item['fr'] = "user_email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy2_301402";
    item['fr'] = "entreprise";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_731440";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_field_1005977";
    item['fr'] = "Email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_fieldcopy_73509";
    item['fr'] = "Mot de passe";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button2_11103";
    item['fr'] = "Connexion";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button2_573400";
    item['fr'] = "Connexion";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_fieldcopy_595166";
    item['fr'] = "Mot de passe";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_field_315205";
    item['fr'] = "Email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_618301";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy2_173687";
    item['fr'] = "entreprise";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy_567247";
    item['fr'] = "user_email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text3_328710";
    item['fr'] = "username";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text2_48653";
    item['fr'] = "Authentification en cours…";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_325687";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button2_184432";
    item['fr'] = "Connexion";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_fieldcopy_695684";
    item['fr'] = "Mot de passe";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_field_886468";
    item['fr'] = "Email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_872606";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy2_971536";
    item['fr'] = "entreprise";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy_754695";
    item['fr'] = "user_email";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text3_1047123";
    item['fr'] = "username";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text2_91910";
    item['fr'] = "Authentification en cours…";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_986242";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "decheteries_text2_64293";
    item['fr'] = "Votre déchèterie";
    
    item = {};
    this.items.push(item);
    item['key'] = "decheteries_textcopy_722639";
    item['fr'] = "Choisir un site";
    
    item = {};
    this.items.push(item);
    item['key'] = "decheteries_textcopy_303751";
    item['fr'] = "Actuellement";
    
    item = {};
    this.items.push(item);
    item['key'] = "decheteries_textcopy2_16613";
    item['fr'] = "faible";
    
    item = {};
    this.items.push(item);
    item['key'] = "decheteries_textcopy3_80354";
    item['fr'] = "Affluence";
    
    item = {};
    this.items.push(item);
    item['key'] = "decheteries_textcopy4_761326";
    item['fr'] = "ouvert";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
