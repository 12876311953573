import React, { Component } from 'react';
import './App.css';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';
import Button from 'muicss/lib/react/button';
import Input from 'muicss/lib/react/input';

export default class ConnexionScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    // Check if Firebase login has been completed.
         firebase.auth().onAuthStateChanged(user => {
         if (user && this.props.appActions.dataSlots['ds_login_screen_state'] == 0) {
           this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid); 
           this.props.appActions.updateDataSlot('ds_login_screen_state', 1); 
         } else if (user && this.props.appActions.dataSlots['ds_login_screen_state'] == 1){
         } else if (user && this.props.appActions.dataSlots['ds_login_screen_state'] == 2){
         	this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid); 
           this.props.appActions.updateDataSlot('ds_login_screen_state', 1); 
         } else if (!user && this.props.appActions.dataSlots['ds_login_screen_state'] == 0) {
           this.props.appActions.updateDataSlot('ds_login_screen_state', 2); 
         } else if (!user && this.props.appActions.dataSlots['ds_login_screen_state'] == 1) {
           this.props.appActions.updateDataSlot('ds_login_screen_state', 2); 
         } else if (!user && this.props.appActions.dataSlots['ds_login_screen_state'] == 2) {
         } else {
         }
         })
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_state0_elText986242 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen ConnexionScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground" style={style_state0_elBackground_outer}>
            <div className="appBg" style={style_state0_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elText986242">
            <div className="baseFont" style={style_state0_elText986242}>
              <div>{this.props.locStrings.connexion_text_986242}</div>
            </div>
          </div>
          
          <div className="state0_elText2">
            <div className="baseFont" style={style_state0_elText2}>
              <div>{this.props.locStrings.connexion_text2_91910}</div>
            </div>
          </div>
          
          <div className="state0_elProgress466744">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#65d0c4'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Connexion</div></Appbar>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  onClick_state1_elButton = (ev) => {
    let newVal = this.props.dataSheetRow.entreprise;
    this.props.appActions.updateDataSlot('ds_entreprise_id', newVal);
    
    newVal = this.props.dataSheetRow.user_name;
    this.props.appActions.updateDataSlot('ds_username', newVal);
    
    newVal = this.props.dataSheetRow.entreprise_name;
    this.props.appActions.updateDataSlot('ds_entreprise', newVal);
    
    newVal = this.props.dataSheetRow.admin;
    this.props.appActions.updateDataSlot('ds_admin', newVal);
    
    newVal = this.props.dataSheetRow.forecast;
    this.props.appActions.updateDataSlot('ds_right_forecast', newVal);
  
    const targetTable_switch1 = (admin, right_forecast) => {
      if (admin == 1) {
        if (right_forecast == 1) {
          return 'decheteries';
        } else if (right_forecast == 0) {
          return 'decheteries'
        }
      }
    }
    
    
    let admin = this.props.appActions.dataSlots['ds_admin'];
    let right_forecast = this.props.appActions.dataSlots['ds_right_forecast'];
    
    this.props.appActions.goToScreen(targetTable_switch1(admin, right_forecast), { transitionId: 'fadeIn' });
  
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state1_elText986242 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state1_elText3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_text3 = this.props.dataSheetRow ? this.props.dataSheetRow.user_name : '';
    
    const style_state1_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_textCopy = this.props.dataSheetRow ? this.props.dataSheetRow.email : '';
    
    const style_state1_elTextCopy2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_textCopy2 = this.props.dataSheetRow ? this.props.dataSheetRow.entreprise_name : '';
    
    const style_state1_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen ConnexionScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elText986242">
            <div className="baseFont" style={style_state1_elText986242}>
              <div>{this.props.locStrings.connexion_text_986242}</div>
            </div>
          </div>
          
          <div className="state1_elText3">
            <div className="baseFont" style={style_state1_elText3}>
              <div>{value_text3 !== undefined ? value_text3 : (<span className="propValueMissing">{this.props.locStrings.connexion_text3_1047123}</span>)}</div>
            </div>
          </div>
          
          <div className="state1_elTextCopy">
            <div className="baseFont" style={style_state1_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.connexion_textcopy_754695}</span>)}</div>
            </div>
          </div>
          
          <div className="state1_elTextCopy2">
            <div className="baseFont" style={style_state1_elTextCopy2}>
              <div>{value_textCopy2 !== undefined ? value_textCopy2 : (<span className="propValueMissing">{this.props.locStrings.connexion_textcopy2_971536}</span>)}</div>
            </div>
          </div>
          
          <div className="state1_elButton">
            <Button className="actionFont" style={style_state1_elButton}  color="accent" onClick={this.onClick_state1_elButton} >
              {this.props.locStrings.connexion_button_872606}
            </Button>
          </div>
          
          <div className="state1_elProgress466744">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#65d0c4'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Connexion</div></Appbar>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  textInputChanged_email = (event) => {
    this.setState({email: event.target.value});
  }
  
  textInputChanged_password = (event) => {
    this.setState({password: event.target.value});
  }
  
  onClick_state2_elConnexion = (ev) => {
    // 'Unlock gate' action.
    const dataFromElements = {  // This object contains the data collected from the UI
      text: this.state.text_plainText,
      text2: this.state.text2_plainText,
      text3: this.state.text3_plainText,
      textCopy: this.state.textCopy_plainText,
      textCopy2: this.state.textCopy2_plainText,
      email: this.state.email,
      password: this.state.password,
    };
    
    this.sendLogin(dataFromElements, (err) => {
      if (err) {
        console.log('** login failed: ', err);
        this.props.appActions.goToScreen('connexion', { errorText: ''+err });
      } else {
      }
    });
  
  }
  
  
  sendLogin() {
    let err;
    if (firebase.auth().currentUser) {
      this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
      this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
    } else {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(() => {
          console.log('log in success');
          this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
          this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
        }).catch((error) => {
          console.log('** login failed: ', err);
          this.props.appActions.goToScreen('connexion', { errorText: ''+err });
        });
      });
    }
  }
  
  
  
  
  renderState2() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state2_elText986242 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state2_elEmail = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      pointerEvents: 'auto',
     };
    
    const style_state2_elPassword = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      pointerEvents: 'auto',
     };
    
    const style_state2_elConnexion = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen ConnexionScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state2_elText986242">
            <div className="baseFont" style={style_state2_elText986242}>
              <div>{this.props.locStrings.connexion_text_986242}</div>
            </div>
          </div>
          
          <div className="state2_elEmail">
            <Input className="baseFont" style={style_state2_elEmail} type="email" hint={this.props.locStrings.connexion_field_886468} onChange={this.textInputChanged_email} value={this.state.email}  />
          </div>
          
          <div className="state2_elPassword">
            <Input className="baseFont" style={style_state2_elPassword} type="password" hint={this.props.locStrings.connexion_fieldcopy_695684} onChange={this.textInputChanged_password} value={this.state.password}  />
          </div>
          
          <div className="state2_elConnexion">
            <Button className="actionFont" style={style_state2_elConnexion}  color="accent" onClick={this.onClick_state2_elConnexion} >
              {this.props.locStrings.connexion_button2_184432}
            </Button>
          </div>
          
          <div className="state2_elProgress466744">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#65d0c4'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Connexion</div></Appbar>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.props.ds_login_screen_state !== undefined) ? this.props.ds_login_screen_state : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
