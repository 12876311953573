import React, { Component } from 'react';
import './App.css';
import img_elN15_4 from './images/Niveau_elN15_4_668229.png';
import img_elN15_3 from './images/Niveau_elN15_3_988931.png';
import img_elN15_2 from './images/Niveau_elN15_2_953615.png';
import img_elN15_1 from './images/Niveau_elN15_1_114666.png';
import img_elN15_0 from './images/Niveau_elN15_0_520991.png';
import img_elN14_4 from './images/Niveau_elN14_4_762591.png';
import img_elN14_3 from './images/Niveau_elN14_3_690740.png';
import img_elN14_2 from './images/Niveau_elN14_2_227980.png';
import img_elN14_1 from './images/Niveau_elN14_1_505036.png';
import img_elN14_0 from './images/Niveau_elN15_0_520991.png';
import img_elN13_4 from './images/Niveau_elN13_4_1033463.png';
import img_elN13_3 from './images/Niveau_elN13_3_316512.png';
import img_elN13_2 from './images/Niveau_elN13_2_1015823.png';
import img_elN13_1 from './images/Niveau_elN13_1_773762.png';
import img_elN13_0 from './images/Niveau_elN13_0_943050.png';
import img_elN12_4 from './images/Niveau_elN12_4_561186.png';
import img_elN12_3 from './images/Niveau_elN12_3_459130.png';
import img_elN12_2 from './images/Niveau_elN12_2_85662.png';
import img_elN12_1 from './images/Niveau_elN12_1_517303.png';
import img_elN12_0 from './images/Niveau_elN15_0_520991.png';
import img_elN11_4 from './images/Niveau_elN11_4_517699.png';
import img_elN11_3 from './images/Niveau_elN11_3_249288.png';
import img_elN11_2 from './images/Niveau_elN11_2_832861.png';
import img_elN11_1 from './images/Niveau_elN11_1_924040.png';
import img_elN11_0 from './images/Niveau_elN13_4_1033463.png';
import img_elN10_4 from './images/Niveau_elN10_4_633418.png';
import img_elN10_3 from './images/Niveau_elN10_3_713065.png';
import img_elN10_2 from './images/Niveau_elN10_2_563253.png';
import img_elN10_1 from './images/Niveau_elN10_1_316186.png';
import img_elN10_0 from './images/Niveau_elN10_0_115400.png';
import img_elN9_4 from './images/Niveau_elN9_4_699031.png';
import img_elN9_3 from './images/Niveau_elN9_3_627199.png';
import img_elN9_2 from './images/Niveau_elN9_2_734584.png';
import img_elN9_1 from './images/Niveau_elN9_1_631027.png';
import img_elN9_0 from './images/Niveau_elN10_0_115400.png';
import img_elN8_4 from './images/Niveau_elN8_4_850746.png';
import img_elN8_3 from './images/Niveau_elN8_3_897474.png';
import img_elN8_2 from './images/Niveau_elN8_2_1018416.png';
import img_elN8_1 from './images/Niveau_elN8_1_351058.png';
import img_elN8_0 from './images/Niveau_elN15_0_520991.png';
import img_elN7_4 from './images/Niveau_elN7_4_841953.png';
import img_elN7_3 from './images/Niveau_elN7_3_117417.png';
import img_elN7_2 from './images/Niveau_elN7_2_718293.png';
import img_elN7_1 from './images/Niveau_elN7_1_416327.png';
import img_elN7_0 from './images/Niveau_elN15_0_520991.png';
import img_elN6_4 from './images/Niveau_elN6_4_827354.png';
import img_elN6_3 from './images/Niveau_elN6_3_817226.png';
import img_elN6_2 from './images/Niveau_elN6_2_1024810.png';
import img_elN6_1 from './images/Niveau_elN6_1_551284.png';
import img_elN6_0 from './images/Niveau_elN15_0_520991.png';
import img_elN5_4 from './images/Niveau_elN5_4_767741.png';
import img_elN5_3 from './images/Niveau_elN5_3_924018.png';
import img_elN5_2 from './images/Niveau_elN5_2_1042774.png';
import img_elN5_1 from './images/Niveau_elN5_1_847480.png';
import img_elN5_0 from './images/Niveau_elN15_0_520991.png';
import img_elN4_4 from './images/Niveau_elN4_4_524038.png';
import img_elN4_3 from './images/Niveau_elN4_3_381311.png';
import img_elN4_2 from './images/Niveau_elN4_2_779206.png';
import img_elN4_1 from './images/Niveau_elN4_1_633272.png';
import img_elN4_0 from './images/Niveau_elN13_4_1033463.png';
import img_elN3_4 from './images/Niveau_elN3_4_594593.png';
import img_elN3_3 from './images/Niveau_elN3_3_830614.png';
import img_elN3_2 from './images/Niveau_elN3_2_345800.png';
import img_elN3_1 from './images/Niveau_elN3_1_1046912.png';
import img_elN3_0 from './images/Niveau_elN13_4_1033463.png';
import img_elN2_4 from './images/Niveau_elN2_4_216300.png';
import img_elN2_3 from './images/Niveau_elN2_3_889200.png';
import img_elN2_2 from './images/Niveau_elN2_2_298583.png';
import img_elN2_1 from './images/Niveau_elN2_1_527797.png';
import img_elN2_0 from './images/Niveau_elN15_0_520991.png';
import img_elN1_4 from './images/Niveau_elN1_4_485032.png';
import img_elN1_3 from './images/Niveau_elN1_3_544638.png';
import img_elN1_2 from './images/Niveau_elN1_2_9999.png';
import img_elN1_1 from './images/Niveau_elN1_1_712828.png';
import img_elN1_0 from './images/Niveau_elN15_0_520991.png';
import img_elN0_4 from './images/Niveau_elN0_4_792388.png';
import img_elN0_3 from './images/Niveau_elN0_3_534452.png';
import img_elN0_2 from './images/Niveau_elN0_2_166982.png';
import img_elN0_1 from './images/Niveau_elN0_1_145075.png';
import img_elN0_0 from './images/Niveau_elN15_0_520991.png';
import img_elImage from './images/Niveau_elN0_4_792388.png';

export default class Niveau extends Component {

  // Properties used by this component:
  // label, flux_id, ts

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elN15_4 = {
      backgroundImage: 'url('+img_elN15_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN15_3 = {
      backgroundImage: 'url('+img_elN15_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN15_2 = {
      backgroundImage: 'url('+img_elN15_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN15_1 = {
      backgroundImage: 'url('+img_elN15_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN15_0 = {
      backgroundImage: 'url('+img_elN15_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN14_4 = {
      backgroundImage: 'url('+img_elN14_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN14_3 = {
      backgroundImage: 'url('+img_elN14_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN14_2 = {
      backgroundImage: 'url('+img_elN14_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN14_1 = {
      backgroundImage: 'url('+img_elN14_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN14_0 = {
      backgroundImage: 'url('+img_elN14_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN13_4 = {
      backgroundImage: 'url('+img_elN13_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN13_3 = {
      backgroundImage: 'url('+img_elN13_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN13_2 = {
      backgroundImage: 'url('+img_elN13_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN13_1 = {
      backgroundImage: 'url('+img_elN13_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN13_0 = {
      backgroundImage: 'url('+img_elN13_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN12_4 = {
      backgroundImage: 'url('+img_elN12_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN12_3 = {
      backgroundImage: 'url('+img_elN12_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN12_2 = {
      backgroundImage: 'url('+img_elN12_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN12_1 = {
      backgroundImage: 'url('+img_elN12_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN12_0 = {
      backgroundImage: 'url('+img_elN12_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN11_4 = {
      backgroundImage: 'url('+img_elN11_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN11_3 = {
      backgroundImage: 'url('+img_elN11_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN11_2 = {
      backgroundImage: 'url('+img_elN11_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN11_1 = {
      backgroundImage: 'url('+img_elN11_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN11_0 = {
      backgroundImage: 'url('+img_elN11_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN10_4 = {
      backgroundImage: 'url('+img_elN10_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN10_3 = {
      backgroundImage: 'url('+img_elN10_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN10_2 = {
      backgroundImage: 'url('+img_elN10_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN10_1 = {
      backgroundImage: 'url('+img_elN10_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN10_0 = {
      backgroundImage: 'url('+img_elN10_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN9_4 = {
      backgroundImage: 'url('+img_elN9_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN9_3 = {
      backgroundImage: 'url('+img_elN9_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN9_2 = {
      backgroundImage: 'url('+img_elN9_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN9_1 = {
      backgroundImage: 'url('+img_elN9_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN9_0 = {
      backgroundImage: 'url('+img_elN9_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN8_4 = {
      backgroundImage: 'url('+img_elN8_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN8_3 = {
      backgroundImage: 'url('+img_elN8_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN8_2 = {
      backgroundImage: 'url('+img_elN8_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN8_1 = {
      backgroundImage: 'url('+img_elN8_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN8_0 = {
      backgroundImage: 'url('+img_elN8_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN7_4 = {
      backgroundImage: 'url('+img_elN7_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN7_3 = {
      backgroundImage: 'url('+img_elN7_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN7_2 = {
      backgroundImage: 'url('+img_elN7_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN7_1 = {
      backgroundImage: 'url('+img_elN7_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN7_0 = {
      backgroundImage: 'url('+img_elN7_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN6_4 = {
      backgroundImage: 'url('+img_elN6_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN6_3 = {
      backgroundImage: 'url('+img_elN6_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN6_2 = {
      backgroundImage: 'url('+img_elN6_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN6_1 = {
      backgroundImage: 'url('+img_elN6_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN6_0 = {
      backgroundImage: 'url('+img_elN6_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN5_4 = {
      backgroundImage: 'url('+img_elN5_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN5_3 = {
      backgroundImage: 'url('+img_elN5_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN5_2 = {
      backgroundImage: 'url('+img_elN5_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN5_1 = {
      backgroundImage: 'url('+img_elN5_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN5_0 = {
      backgroundImage: 'url('+img_elN5_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN4_4 = {
      backgroundImage: 'url('+img_elN4_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN4_3 = {
      backgroundImage: 'url('+img_elN4_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN4_2 = {
      backgroundImage: 'url('+img_elN4_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN4_1 = {
      backgroundImage: 'url('+img_elN4_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN4_0 = {
      backgroundImage: 'url('+img_elN4_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN3_4 = {
      backgroundImage: 'url('+img_elN3_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN3_3 = {
      backgroundImage: 'url('+img_elN3_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN3_2 = {
      backgroundImage: 'url('+img_elN3_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN3_1 = {
      backgroundImage: 'url('+img_elN3_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN3_0 = {
      backgroundImage: 'url('+img_elN3_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN2_4 = {
      backgroundImage: 'url('+img_elN2_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN2_3 = {
      backgroundImage: 'url('+img_elN2_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN2_2 = {
      backgroundImage: 'url('+img_elN2_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN2_1 = {
      backgroundImage: 'url('+img_elN2_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN2_0 = {
      backgroundImage: 'url('+img_elN2_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN1_4 = {
      backgroundImage: 'url('+img_elN1_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN1_3 = {
      backgroundImage: 'url('+img_elN1_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN1_2 = {
      backgroundImage: 'url('+img_elN1_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN1_1 = {
      backgroundImage: 'url('+img_elN1_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN1_0 = {
      backgroundImage: 'url('+img_elN1_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_4 = {
      backgroundImage: 'url('+img_elN0_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_3 = {
      backgroundImage: 'url('+img_elN0_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_2 = {
      backgroundImage: 'url('+img_elN0_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_1 = {
      backgroundImage: 'url('+img_elN0_1+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_0 = {
      backgroundImage: 'url('+img_elN0_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    let transformPropValue_image = (input) => {
      let item = this.props.dataSheetRow;
      
      let img = [
        [img_elN0_0, img_elN0_1, img_elN0_2, img_elN0_3, img_elN0_4],
        [img_elN1_0, img_elN1_1, img_elN1_2, img_elN1_3, img_elN1_4],
        [img_elN2_0, img_elN2_1, img_elN2_2, img_elN2_3, img_elN2_4],
        [img_elN3_0, img_elN3_1, img_elN3_2, img_elN3_3, img_elN3_4],
        [img_elN4_0, img_elN4_1, img_elN4_2, img_elN4_3, img_elN4_4],
        [img_elN5_0, img_elN5_1, img_elN5_2, img_elN5_3, img_elN5_4],
        [img_elN6_0, img_elN6_1, img_elN6_2, img_elN6_3, img_elN6_4],
        [img_elN7_0, img_elN7_1, img_elN7_2, img_elN7_3, img_elN7_4],
        [img_elN8_0, img_elN8_1, img_elN8_2, img_elN8_3, img_elN8_4],
        [img_elN9_0, img_elN9_1, img_elN9_2, img_elN9_3, img_elN9_4],
        [img_elN10_0, img_elN10_1, img_elN10_2, img_elN10_3, img_elN10_4],
        [img_elN11_0, img_elN11_1, img_elN11_2, img_elN11_3, img_elN11_4],
        [img_elN12_0, img_elN12_1, img_elN12_2, img_elN12_3, img_elN12_4],
        [img_elN13_0, img_elN13_1, img_elN13_2, img_elN13_3, img_elN13_4],
        [img_elN14_0, img_elN14_1, img_elN14_2, img_elN14_3, img_elN14_4],
        [img_elN15_0, img_elN15_1, img_elN15_2, img_elN15_3, img_elN15_4],
      ];
      
      return img[input][item.state];
    }
    const style_elImage = {
      backgroundImage: 'url('+(transformPropValue_image(this.props.flux_id) || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_text = this.props.label;
    
    
    return (
      <div className="Niveau appBg">
        <div className="layoutFlow">
          <div className="flowRow flowRow_Niveau_elN15_4_668229">
          <div className="elN15_4">
            <div style={style_elN15_4} />
          </div>
          
          <div className="elN15_3">
            <div style={style_elN15_3} />
          </div>
          
          <div className="elN15_2">
            <div style={style_elN15_2} />
          </div>
          
          <div className="elN15_1">
            <div style={style_elN15_1} />
          </div>
          
          <div className="elN15_0">
            <div style={style_elN15_0} />
          </div>
          
          <div className="elN14_4">
            <div style={style_elN14_4} />
          </div>
          
          <div className="elN14_3">
            <div style={style_elN14_3} />
          </div>
          
          <div className="elN14_2">
            <div style={style_elN14_2} />
          </div>
          
          <div className="elN14_1">
            <div style={style_elN14_1} />
          </div>
          
          <div className="elN14_0">
            <div style={style_elN14_0} />
          </div>
          
          <div className="elN13_4">
            <div style={style_elN13_4} />
          </div>
          
          <div className="elN13_3">
            <div style={style_elN13_3} />
          </div>
          
          <div className="elN13_2">
            <div style={style_elN13_2} />
          </div>
          
          <div className="elN13_1">
            <div style={style_elN13_1} />
          </div>
          
          <div className="elN13_0">
            <div style={style_elN13_0} />
          </div>
          
          <div className="elN12_4">
            <div style={style_elN12_4} />
          </div>
          
          <div className="elN12_3">
            <div style={style_elN12_3} />
          </div>
          
          <div className="elN12_2">
            <div style={style_elN12_2} />
          </div>
          
          <div className="elN12_1">
            <div style={style_elN12_1} />
          </div>
          
          <div className="elN12_0">
            <div style={style_elN12_0} />
          </div>
          
          <div className="elN11_4">
            <div style={style_elN11_4} />
          </div>
          
          <div className="elN11_3">
            <div style={style_elN11_3} />
          </div>
          
          <div className="elN11_2">
            <div style={style_elN11_2} />
          </div>
          
          <div className="elN11_1">
            <div style={style_elN11_1} />
          </div>
          
          <div className="elN11_0">
            <div style={style_elN11_0} />
          </div>
          
          <div className="elN10_4">
            <div style={style_elN10_4} />
          </div>
          
          <div className="elN10_3">
            <div style={style_elN10_3} />
          </div>
          
          <div className="elN10_2">
            <div style={style_elN10_2} />
          </div>
          
          <div className="elN10_1">
            <div style={style_elN10_1} />
          </div>
          
          <div className="elN10_0">
            <div style={style_elN10_0} />
          </div>
          
          <div className="elN9_4">
            <div style={style_elN9_4} />
          </div>
          
          <div className="elN9_3">
            <div style={style_elN9_3} />
          </div>
          
          <div className="elN9_2">
            <div style={style_elN9_2} />
          </div>
          
          <div className="elN9_1">
            <div style={style_elN9_1} />
          </div>
          
          <div className="elN9_0">
            <div style={style_elN9_0} />
          </div>
          
          <div className="elN8_4">
            <div style={style_elN8_4} />
          </div>
          
          <div className="elN8_3">
            <div style={style_elN8_3} />
          </div>
          
          <div className="elN8_2">
            <div style={style_elN8_2} />
          </div>
          
          <div className="elN8_1">
            <div style={style_elN8_1} />
          </div>
          
          <div className="elN8_0">
            <div style={style_elN8_0} />
          </div>
          
          <div className="elN7_4">
            <div style={style_elN7_4} />
          </div>
          
          <div className="elN7_3">
            <div style={style_elN7_3} />
          </div>
          
          <div className="elN7_2">
            <div style={style_elN7_2} />
          </div>
          
          <div className="elN7_1">
            <div style={style_elN7_1} />
          </div>
          
          <div className="elN7_0">
            <div style={style_elN7_0} />
          </div>
          
          <div className="elN6_4">
            <div style={style_elN6_4} />
          </div>
          
          <div className="elN6_3">
            <div style={style_elN6_3} />
          </div>
          
          <div className="elN6_2">
            <div style={style_elN6_2} />
          </div>
          
          <div className="elN6_1">
            <div style={style_elN6_1} />
          </div>
          
          <div className="elN6_0">
            <div style={style_elN6_0} />
          </div>
          
          <div className="elN5_4">
            <div style={style_elN5_4} />
          </div>
          
          <div className="elN5_3">
            <div style={style_elN5_3} />
          </div>
          
          <div className="elN5_2">
            <div style={style_elN5_2} />
          </div>
          
          <div className="elN5_1">
            <div style={style_elN5_1} />
          </div>
          
          <div className="elN5_0">
            <div style={style_elN5_0} />
          </div>
          
          <div className="elN4_4">
            <div style={style_elN4_4} />
          </div>
          
          <div className="elN4_3">
            <div style={style_elN4_3} />
          </div>
          
          <div className="elN4_2">
            <div style={style_elN4_2} />
          </div>
          
          <div className="elN4_1">
            <div style={style_elN4_1} />
          </div>
          
          <div className="elN4_0">
            <div style={style_elN4_0} />
          </div>
          
          <div className="elN3_4">
            <div style={style_elN3_4} />
          </div>
          
          <div className="elN3_3">
            <div style={style_elN3_3} />
          </div>
          
          <div className="elN3_2">
            <div style={style_elN3_2} />
          </div>
          
          <div className="elN3_1">
            <div style={style_elN3_1} />
          </div>
          
          <div className="elN3_0">
            <div style={style_elN3_0} />
          </div>
          
          <div className="elN2_4">
            <div style={style_elN2_4} />
          </div>
          
          <div className="elN2_3">
            <div style={style_elN2_3} />
          </div>
          
          <div className="elN2_2">
            <div style={style_elN2_2} />
          </div>
          
          <div className="elN2_1">
            <div style={style_elN2_1} />
          </div>
          
          <div className="elN2_0">
            <div style={style_elN2_0} />
          </div>
          
          <div className="elN1_4">
            <div style={style_elN1_4} />
          </div>
          
          <div className="elN1_3">
            <div style={style_elN1_3} />
          </div>
          
          <div className="elN1_2">
            <div style={style_elN1_2} />
          </div>
          
          <div className="elN1_1">
            <div style={style_elN1_1} />
          </div>
          
          <div className="elN1_0">
            <div style={style_elN1_0} />
          </div>
          
          <div className="elN0_4">
            <div style={style_elN0_4} />
          </div>
          
          <div className="elN0_3">
            <div style={style_elN0_3} />
          </div>
          
          <div className="elN0_2">
            <div style={style_elN0_2} />
          </div>
          
          <div className="elN0_1">
            <div style={style_elN0_1} />
          </div>
          
          <div className="elN0_0">
            <div style={style_elN0_0} />
          </div>
          
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          </div>
        </div>
        
        <div className="foreground">
          <div className="baseFont elText" style={style_elText}>
            <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.component1_text_329730}</span>)}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
