import React, { Component } from 'react';
import './App.css';
import Niveau from './Niveau';
import btn_icon_813582 from './images/btn_icon_813582.png';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Appbar from 'muicss/lib/react/appbar';

export default class DecheteriesScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      picker: '',
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['niveaux'];
      let serviceOptions = this.props.appActions.serviceOptions_niveaux;
      if ( !this.props.appActions.dataSheetLoaded['niveaux']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/sites/$slot('ds_selected_site_id')/points", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['niveaux'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['sites'];
      let serviceOptions = this.props.appActions.serviceOptions_sites;
      if ( !this.props.appActions.dataSheetLoaded['sites']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/sites", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['sites'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  pickerValueChanged_picker = (event) => {
    this.setState({picker: event.target.value});
    
    let newVal = event.target.value;
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.
      let site = this.props.appActions.dataSheets['sites'].items.filter(it => it.document_key == input)[0];
      this.props.appActions.updateDataSlot('ds_site_display_name', site.display_name);
      this.props.appActions.updateDataSlot('ds_ouverture', site.ouverture);
      return input;
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot("ds_selected_site_id", newVal);
  }
  
  onClick_elIconButton = (ev) => {
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    this.props.appActions.goToScreen('connexion');
  
    // Go to screen 'Connexion'
    this.props.appActions.goToScreen('connexion', { transitionId: 'fadeIn' });
  
  }
  
  
  clearSavedLogin() {
    // Logout
    firebase.auth().signOut();
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const dataSheet_niveaux = this.props.dataSheets['niveaux'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    
    let selection_picker = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.props.appActions.getDataSheet('sites');
    const valueColumnName_picker = 'document_key';
    const labelColumnName_picker = 'display_name';
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextCopy4 = {
      color: '#28cd41',
      textAlign: 'left',
     };
    const style_elTextCopy3 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTextCopy2 = {
      color: '#28cd41',
      textAlign: 'left',
     };
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    let filterItems_list = items => {
      // This function filters items for the List / Grid element.
      // There is a variable named 'items' that provides item values.
      let max_ts = Math.max.apply(Math, items.map(function(o) { return o.ts; }))
      let out = items.filter(it => it.ts == max_ts);
      console.log(out);
      
      return out;
      
    
    }
    items_list = items_list.concat(filterItems_list(this.props.appActions.getDataSheet('niveaux').items));
    
    const style_elEmbed = {
      pointerEvents: 'auto',
     };
    
    // Embedded HTML content for element 'embed'
    const htmlContent_embed = "<iframe\n  width=\"400\"\n  height=\"300\"\n  frameborder=\"0\" style=\"border:0\"\n  src=\"https://www.google.com/maps/embed/v1/place?key=AIzaSyBzlaY33D-BV-FLGKip83nG3xgrErZTqdM\n    &q=porte+versailles+paris\" allowfullscreen>\n</iframe>";
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_813582+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '122.500%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen DecheteriesScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elText2">
            <div className="baseFont" style={style_elText2}>
              <div>{this.props.locStrings.decheteries_text2_64293}</div>
            </div>
          </div>
          
          <div className="elPicker">
            <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_picker} value={selection_picker} >
              {dataSource_picker.items.every(item => {
                return item[valueColumnName_picker] !== selection_picker;
              }) ? <Option value=''/> : null}
              {dataSource_picker.items.map(item => {
                const colValue = item[valueColumnName_picker];
                const labelColValue = item[labelColumnName_picker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{this.props.locStrings.decheteries_textcopy_303751}</div>
            </div>
          </div>
          
          <div className="elTextCopy4">
            <div className="baseFont" style={style_elTextCopy4}>
              <div>{this.props.locStrings.decheteries_textcopy4_761326}</div>
            </div>
          </div>
          
          <div className="elTextCopy3">
            <div className="baseFont" style={style_elTextCopy3}>
              <div>{this.props.locStrings.decheteries_textcopy3_80354}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="baseFont" style={style_elTextCopy2}>
              <div>{this.props.locStrings.decheteries_textcopy2_16613}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemClasses = `gridItem cols2_${index % 2} cols5_${index % 5} cols9_${index % 9}`;
                let itemComp = (row._componentId) ? listComps_list[row._componentId] : <Niveau dataSheetId={'niveaux'} dataSheetRow={row} {...{ 'label': row['label'], 'flux_id': row['flux_id'], 'ts': row['ts'], }} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;
                return (
                  <div className={itemClasses} key={row.key}>
                    {itemComp}
                  </div>
                )
              })}
              <div ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
          
          <div className="embeddedContent elEmbed">
            <div style={style_elEmbed}>
              <div dangerouslySetInnerHTML={{__html: htmlContent_embed}}></div>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Decheteries</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
        </div>
      </div>
    )
  }
  
}
